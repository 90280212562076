/*botões de compartilhamento nas redes sociais nos cards*/

$(".share-box").each(function(){
$(this).jsSocials({
    showLabel: false,
    showCount: false,
    shares: ["email", "twitter", "facebook", "googleplus", "linkedin", "whatsapp"],
    url: window.location.hostname + $(this).closest('a').attr('href')
});
});

$('.share-box').collapse({
    toggle: false
})

$('.btn-share').click(function(e){
    e.preventDefault();
    $(this).siblings('.share-box').collapse('toggle');
});